import React from "react";

import Layout from "../templates/Layout";


class NotFound extends React.Component {
    render() {

        return (
            <Layout
                title="Error 404: Page not found"
                location={this.props.location}
            >
                <h1>404 not found</h1>
                <p>It's likely this page doesn't exist - please try somewhere else</p>
            </Layout>
        )
    }
}
        
export default NotFound